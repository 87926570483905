<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ !row ? 'הוספת' : 'עדכון' }} הודעה ללוח</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section style="overflow: auto;" class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field label="הזן כותרת">
            <b-input v-model="model.messageTitle"></b-input>
          </b-field>
          <FieldInlineDateTime label="מתאריך" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
            v-model="model.displayFromDate" />
          <FieldInlineDateTime label="עד תאריך" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
            v-model="model.displayToDate" />
          <b-field label="הזן מחבר">
            <b-input v-model="model.messageAuthor"></b-input>
          </b-field>
          <b-field label="יוצג רק לקבוצה מספר">
            <!-- <b-input type="number" v-model="model.showToGroupId"></b-input> -->
            <FieldInlineSelect @onChange="onChange" :filterable="true" :clearable="true"
              :optionsAsync="getGroups" v-model="model.showToGroupId" />
          </b-field>
          <b-field label="תוכן">
            <!-- <ckeditor :editor="editor" v-model="model.messageBody" :config="editorConfig"></ckeditor> -->
            <NewActivityEditor @onEditorChange="onEditorChange" :ckEditorContent="model.messageBody" :content="model.messageBody" ref="editor" />
          </b-field>
          <b-field label="סטטוס">
            <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light" class="checkbox">
              פעיל
            </b-checkbox>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :disabled="!isValidForm" :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button v-if="row" :loading="isLoading" @click="duplicate" label="שכפל" type="is-info" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
import LessonService from "../services/LessonService";
import NewActivityEditor from "./NewActivityEditor.vue";

const {
    ClassicEditor,
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Bookmark,
    CloudServices,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    //
    MediaEmbed,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline
} = CKEDITOR;

export default {
  name: "DialogCourseMessage",
  props: ["store", 'row'],
  components: {
    FieldInlineDateTime,
    FieldInlineSelect,
    NewActivityEditor
  },
  computed: {
    courseId() {
      return this.store.state.auth.course.id;
    },
    isValidForm() {
      return this.model.messageTitle && this.model.displayToDate && this.model.displayFromDate;
    }
  },
  created() {
    if (this.row) this.model = { ...this.row };
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      loading: false,
      model: {
        messageTitle: null,
        messageBody: '',
        messageAuthor: null,
        displayFromDate: null,
        displayToDate: null,
        showToGroupId: null,
        status: false
      },
      editor: ClassicEditor,
      editorConfig: {
                language: 'he',
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        'insertTable',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'outdent',
                        'indent'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    Alignment,
                    Autoformat,
                    Base64UploadAdapter,
                    AutoImage,
                    AutoLink,
                    Autosave,
                    BalloonToolbar,
                    BlockQuote,
                    Bold,
                    Bookmark,
                    CloudServices,
                    Code,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    ImageBlock,
                    ImageCaption,
                    ImageInline,
                    ImageInsertViaUrl,
                    ImageResize,
                    ImageStyle,
                    LinkImage,
                    ImageTextAlternative,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    List,
                    ListProperties,
                    //
                    MediaEmbed,
                    Paragraph,
                    PasteFromMarkdownExperimental,
                    PasteFromOffice,
                    RemoveFormat,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextTransformation,
                    TodoList,
                    Underline
                ],
                balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
                fontFamily: {
                    supportAllValues: true
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        '|',
                        'resizeImage'
                    ]
                },
                licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                menuBar: {
                    isVisible: true
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title']
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote']
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker']
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler']
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark']
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright']
                        }
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                }
            },
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      // this.messageBody = this.$refs.editor.getHTML();
      if (!this.row) {
        AdminService.addCourseMessage(this.courseId, {
          ...this.model
        })
          .then(() => {
            Toast.open({
              type: "is-success",
              message: "הפעולה בוצעה!",
              duration: 4000,
            });
            this.$emit("close");
            window.location.reload();
          }).catch(() => {
            Toast.open({
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          }).finally(() => {
            this.isLoading = false;
          });
      } else {
        AdminService.editCourseMessage(this.courseId, this.row.id, {
          ...this.model
        })
          .then(() => {
            Toast.open({
              type: "is-success",
              message: "הפעולה בוצעה!",
              duration: 4000,
            });
            this.$emit("close");
            window.location.reload();
          }).catch(() => {
            Toast.open({
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          }).finally(() => {
            this.isLoading = false;
          });
      }
    },
    onEditorChange(value) {
      this.model.messageBody = value;
    },
    onChange() { },
    getGroups() {
      return () =>
        LessonService.getGroups(this.store.state.auth.course.id,
          {
            PageSize: 999,
            IsAscending: false,
            PageNumber: 1,
            ResultType: 3
          },
          false).then((r) =>
            r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
          );
    },
    duplicate() {
      const model = {
        messageTitle: this.model.messageTitle,
        messageBody: this.model.messageBody,
        messageAuthor: this.model.messageAuthor,
        displayFromDate: this.model.displayFromDate,
        displayToDate: this.model.displayToDate,
        showToGroupId: this.model.showToGroupId,
        status: this.model.status,
      }
      AdminService.duplicateCourseMessage(this.courseId, model).then(() => {
        Toast.open({
          type: "is-success",
          message: "ההודעה שוכפלה בהצלחה!",
          duration: 4000,
        });
        this.$emit("close");
        window.location.reload();
      })
    }
  },
};
</script>

<style scoped lang="scss"></style>
